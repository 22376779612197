<template>
  <div class="home">
    <img class="logo" src="@/assets/images_n/TITLE.png" alt="">
    <div class="buttons">
      <a @click="game()"><img class="btn" src="@/assets/images_n/參加抽獎.png" alt=""></a>
      <a @click="history()"><img class="btn" src="@/assets/images_n/抽獎紀錄.png" alt=""></a>
      <router-link to="/Rules/1"><img class="btn" src="@/assets/images_n/活動辦法.png" alt=""></router-link>
      <a :href="shareLink"><img class="btn" src="@/assets/images_n/邀請好友.png" alt=""></a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'Home',
  computed: mapState([
    'quota',
    'agreeAt',
    'shareLink'
  ]),
  methods: {
    game(){
      if(!this.quota > 0) {
        return Swal.fire('','抽獎機會已使⽤完畢<br>邀請不同好友加入<br>可享更多抽獎機會！');
      }

      if(this.agreeAt){
        let me = this;
        this.$store.commit('SET_LOADING', true);
        this.$store.dispatch('getPrize').then(result=>{
          if (result.data && result.data.msg === 'success' && result.data.data.status) {
            this.$store.commit('SET_LOADING', false);
            this.$router.push({
              name:'Gaming',
              query:{
                value: result.data.data.awards,
                url: result.data.data.code
              }
            });
          }
        }).catch(err => {
          this.$store.commit('SET_LOADING', false);
          if (err.response && err.response.data && err.response.data.msg === 'no quota') {
            Swal.fire('','抽獎機會已使⽤完畢<br>邀請不同好友加入<br>可享更多抽獎機會！').then(()=>{
              me.$router.push('/');
            });
          }else{
            Swal.fire('','發生錯誤，請稍後再試！')
          }
        });
      } else {
        this.$store.commit('SET_LOADING', false);
        this.$router.push('/Rules/0');
      }
    },
    history(){
      this.$store.dispatch('getHistory').then(()=>{
        this.$router.push('/History');
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  .home
    display: flex
    flex-direction: column
    align-items: center
    height: 100%
    position: relative
    .logo
      height: 19.71vh
      margin: 0 0 3.94vh 0
    .buttons
      flex: 1
      width: 100%
      display: flex
      flex-direction: column
      align-items: center
      z-index: 1
      a
        display: flex
        justify-content: center
        width: 100%
        &:not(:last-child)
            margin-bottom: 3.28vh
        img.btn
          height: 5.51vh
</style>