<template>
  <div id="app">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;700&display=swap" rel="stylesheet">
    <div class="main" :class="{home:routeName==='Home'}">
      <nav :class="{home:routeName==='Home'}">
        <img class="logo" src="@/assets/images_n/LOGO.png" alt="">
        <template v-if="routeName!=='Home'">
          <img class="logo-title" src="@/assets/images_n/logo-title.png" alt="">
        </template>
      </nav>
      <div class="container" :class="{ mask: mask }">
        <div class="mask" v-if="mask"></div>
        <template v-if="loading">
          <div class="loading">
            <div data-content="頁面載入中">頁面載入中</div>
          </div>
        </template>
        <template v-else-if="routeName==='Home'">
          <router-view/>
        </template>
        <template v-else-if="routeName==='Rules'">
          <div class="rules outline">
            <router-view/>
          </div>
          <img class="hands" src="@/assets/images_n/手.png" alt="">
        </template>
        <template v-else-if="routeName==='Gaming'">
          <div class="gaming outline" :class="{ mask: mask }">
            <router-view/>
          </div>
        </template>
        <template v-else>
          <div class="outline">
            <router-view/>
          </div>
          <img v-if="routeName==='History' || routeName==='Results'" class="hands" src="@/assets/images_n/手.png" alt="">
        </template>
      </div>
    </div>
    <footer>
      <img src="@/assets/images_n/警語.jpg" alt="">
    </footer>
  </div>
</template>

<script>
export default {
  computed:{
    routeName(){
      return this.$route.name;
    },
    mask(){
      return this.$store.state.mask;
    },
    loading(){
      return this.$store.state.loading;
    }
  },
  created(){
    this.$store.dispatch('initializeLiff');
  }
}
</script>

<style lang="sass">
  @import "assets/sass/global.sass"
</style>