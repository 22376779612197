<template>
    <div class="results">
        <div class="title" :class="{ winning: value>0,showed: showInfo }">
            <template v-if="value>0">
                <img class="title" src="@/assets/images_n/恭喜您獲得.png" alt="">
                <span class="line-points">LINE POINTS <em>{{ value }} <span>點</span></em></span>
                <img v-show="showInfo" class="vector" src="@/assets/images_n/rules-vector.png" alt="">
            </template>
            <template v-else>
                <div>
                    <img class="title" src="@/assets/images_n/再接再厲.png" alt="">
                </div>
                <img v-show="showInfo" class="vector" src="@/assets/images_n/rules-vector.png" alt="">
                <span v-show="showInfo" class="invite">邀請好友加入帝亞吉歐LINE<br>即享更多抽獎機會</span>
            </template>
        </div>
        <transition name="info">
            <div class="info" v-show="showInfo">
                <template v-if="value>0">
                    <a class="code" :href="'https://points.line.me/pointcode?pincode='+url">兌獎序號：{{ url }}</a>
                    <span v-show="showInfo" class="invite">邀請好友加入帝亞吉歐LINE<br>即享更多抽獎機會</span>
                </template>
            </div>
        </transition>
        <transition name="buttons">
            <div class="buttons" v-show="showInfo">
                <template v-if="value>0">
                    <a :href="'https://points.line.me/pointcode?pincode='+url" target="_blank"><img class="btn" src="@/assets/images_n/立即兌換.png" alt="立即兌換"></a>
                </template>
                <a @click="history()"><img class="btn" src="@/assets/images_n/抽獎紀錄.png" alt="抽獎紀錄"></a>
                <a :href="shareLink"><img class="btn" src="@/assets/images_n/邀請好友.png" alt="邀請好友"></a>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Results',
    data(){
        return {
            showInfo: false
        }
    },
    computed:{
        value(){
            return this.$route.query.value
        },
        url(){
            return this.$route.query.url
        },
        shareLink(){
            return this.$store.state.shareLink;
        }
    },
    mounted(){
        setTimeout(()=>{
            this.showInfo = true;
        },2000);
    },
    methods:{
        history(){
            this.$store.dispatch('getHistory').then(()=>{
              this.$router.push('/History');
            })
        }
    }
}
</script>

<style lang="sass" scoped>
    .results
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        display: flex
        padding: 1em
        div.title
            transition: all .3s
            flex: 1
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            &.showed 
                justify-content: space-around
                .vector
                    width: 70%
                    margin: 2em auto
                .invite
                    font-size: 16px
                    font-family: "NotoSansCJKtc"
                    color: rgb(255, 255, 255)
                    text-align: center
                    font-weight: normal
                    line-height: 160%
                    letter-spacing: 1px
                    word-break: keep-all
            &.winning
                padding-top: 2.62vh
                &.showed
                    .vector
                        width: 100%
                        margin: auto
                .title
                    height: 4.4vh
            >div
                display: flex
                flex-direction: column
                align-items: center
                .title
                    height: 13.84vh
                    padding-top: 3em

                .text
                    height: 2.95vh
            span.line-points
                font-size: 28px
                font-family: "Helvetica"
                color: rgb(0, 185, 2)
                font-weight: bold
                line-height: 1.2
                text-align: center
                text-shadow: 2.298px 1.928px 4.55px rgba(0, 0, 0, 0.75)
                em
                    color: #ffffff
                    font-style: normal 
                    font-size: 1.5em
        .info
            // flex: 1
            width: 80%
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            div
                text-align: center
            .invite
                font-size: 16px
                font-family: "NotoSansCJKtc"
                color: rgb(255, 255, 255)
                text-align: center
                font-weight: normal
                line-height: 160%
                letter-spacing: 1px
            .code
                padding: .5em
                margin: 1em
                background: rgba(0,0,0,.3)
                color: rgb(231, 190, 126)
                text-align: center
                font-size: 16px
                text-decoration: none
                letter-spacing: 1px
                width: 100%
                border-radius: 5px
        .buttons
            flex: 1
            display: flex
            flex-direction: column
            align-items: center
            padding-top: 1em
            margin-bottom: 1em
            a
                width: 100%
                text-align: center
                margin: .75em 0
                img.btn
                    height: 5.51vh

    .info-enter-active,
    .buttons-enter-active
        transition: all .5s
        overflow: hidden
    
    .info-enter-from,
    .buttons-enter-from
        flex: 0

    .info-enter-to
        flex: 1

    .buttons-enter-to
        flex: 1.2
</style>