import Vue from 'vue'
import Vuex from 'vuex'
import liff from '@line/liff';
import axios from 'axios';
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        accessToken: null,
        quota: null,
        agreeAt: null,
        mask: false,
        loading: false,
        shareLink: null,
        histories: []
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        SET_QUOTA: (state, quota) => {
            state.quota = quota;
        },
        SET_AGREE_AT: (state, agreeAt) => {
            state.agreeAt = agreeAt;
        },
        SET_LOADING: (state, loading) => {
            state.loading = loading;
        },
        SET_MASK: (state, mask) => {
            state.mask = mask;
        },
        SET_HISTORIES: (state, histories) => {
            state.histories.splice(0, state.histories.length, ...histories);
        },
        SET_SHARE_LINK: (state, shareLink) => {
            state.shareLink = shareLink;
        }
    },
    actions: {
        initLiff: () => {
            return liff.init({ liffId: process.env.VUE_APP_LIFFID });
        },
        getAccessToken: ({ commit }) => {
            commit('SET_ACCESS_TOKEN', liff.getAccessToken());
        },
        initializeLiff: ({ commit, dispatch }) => {
            commit('SET_LOADING', true);
            dispatch('initLiff').then(() => {
                if (!liff.isLoggedIn()) {
                    liff.login();
                } else {
                    dispatch('getAccessToken').then(() => {
                        dispatch('getProfile');
                        dispatch('getShareLink');
                    });
                }
            })
        },
        getProfile: ({ state, commit }) => {
            axios.post(process.env.VUE_APP_API_BASE_URL + '/api/profile', {}, {
                headers: {
                    'Authorization': 'Bearer ' + state.accessToken
                }
            }).then(result => {
                if (result.data && result.data.msg === 'success') {
                    commit('SET_QUOTA', result.data.data.quota);
                    commit('SET_AGREE_AT', result.data.data.agree_at);
                } else {
                    Swal.fire('', '資料讀取有誤<br>請關閉視窗後再試！');
                }
            }).catch(err => {
                Swal.fire('', '資料讀取有誤<br>請關閉視窗後再試！');
                return err;
            });
        },
        getHistory: ({ state, commit }) => {
            commit('SET_LOADING', true);
            return axios.post(process.env.VUE_APP_API_BASE_URL + '/api/record', {}, {
                headers: {
                    'Authorization': 'Bearer ' + state.accessToken
                }
            }).then(result => {
                if (result.data && result.data.msg === 'success') {
                    commit('SET_HISTORIES', result.data.data.record);
                    commit('SET_LOADING', false);
                } else {
                    commit('SET_LOADING', false);
                    Swal.fire('讀取資料發生錯誤，請重新整理後再試！');
                }
            }).catch(err => {
                commit('SET_LOADING', false);
                Swal.fire('讀取資料發生錯誤，請重新整理後再試！');
                return err;
            });
        },
        getShareLink: ({ state, commit }) => {
            commit('SET_LOADING', true);
            axios.post(process.env.VUE_APP_API_BASE_URL + '/api/getShareLink', {}, {
                headers: {
                    'Authorization': 'Bearer ' + state.accessToken
                }
            }).then(result => {
                if (result.data && result.data.msg === 'success') {
                    commit('SET_SHARE_LINK', result.data.data);
                    commit('SET_LOADING', false);
                } else {
                    commit('SET_LOADING', false);
                    Swal.fire('', '資料讀取有誤<br>請關閉視窗後再試！');
                }
            }).catch(err => {
                commit('SET_LOADING', false);
                Swal.fire('', '資料讀取有誤<br>請關閉視窗後再試！');
                return err;
            });
        },
        agreeTerms: ({ state, commit }) => {
            commit('SET_LOADING', true);
            return axios.post(process.env.VUE_APP_API_BASE_URL + '/api/agree', {}, {
                headers: {
                    'Authorization': 'Bearer ' + state.accessToken
                }
            });
        },
        getPrize: ({ state, commit }) => {
            commit('SET_LOADING', true);
            return axios.post(process.env.VUE_APP_API_BASE_URL + '/api/getPrize', {}, {
                headers: {
                    'Authorization': 'Bearer ' + state.accessToken
                }
            });
        },
    },
    modules: {}
})