<template>
    <div class="history">
        <img class="title" src="@/assets/images_n/抽獎紀錄title.png" alt="">
        <img class="vector" src="@/assets/images_n/rules-vector.png" alt="">
        <span>點擊序號可至LINE POINTS官網確認兌換狀態</span>
        <div class="list">
            <template v-if="histories.length>0">
                <table>
                    <thead>
                        <td>日期</td>
                        <td>點數</td>
                        <td>中獎序號</td>
                    </thead>
                    <tbody>
                        <tr v-for="history,index in histories" :key="index">
                            <td>{{ dateText(history.created_at) }}</td>
                            <td>{{ history.value===0?'未中獎':history.value }}</td>
                            <td>
                                <template v-if="history.value!==0">
                                    <a :href="prizeUrl(history.url)" target="_blank">{{ history.url }}</a>
                                </template>
                                <template v-else>未中獎</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <p>尚未有抽獎紀錄。</p>
            </template>
        </div>
        <router-link to="/"><img src="@/assets/images_n/回首頁.png"></router-link>
    </div>
</template>

<script>
import moment from  'moment';

export default {
  name: 'History',
  computed: {
    histories(){
        return this.$store.state.histories;
    },
  },
  methods: {
    prizeUrl(url){
        return 'https://points.line.me/pointcode?pincode=' + url;
    },
    dateText(date){
        return moment(date).format('M/D');
    }
  }
}
</script>

<style lang="sass" scoped>
    .history
        padding: 5vh 5vw
        height: 100%
        box-sizing: border-box
        display: flex
        flex-direction: column
        align-items: center
        >.title
            height: 4.27vh
        >.vector
            margin-top: 1em
            width: 100%
        >a
            display: flex
            align-items: center
            justify-content: center
        > span
            font-size: 12px
            color: rgb(223, 167, 96)
            text-align: justify
            width: 100%
            margin-top: 1em
            &::after
                content: ''
                display: inline-block
                width: 100%
        .list
            flex:1
            overflow: auto   
            margin-bottom: 1em
            p
                widht: 100%
                background: linear-gradient(to right,#ffffff,#f7d9a7)
                background: -webkit-linear-gradient(to right,#ffffff,#f7d9a7)
                background-clip: text
                -webkit-background-clip: text
                color: transparent
                text-align: center
            table
                width: 100%
                border-collapse: collapse
                td
                    text-align: center
                    padding: .3em
                thead
                    background-image: url('../assets/images_n/Thead.png')
                    td
                        color: rgb(0, 55, 58)
                tbody
                    a
                        border-bottom: 1px solid #ffffff
                        color: #ffffff
                    tr
                        background: rgba(0,0,0,.2)
                    tr:nth-child(even)
                        background: rgba(255,255,255,.2)
                    td
                        color: #ffffff
        a>img
            height: 5.52vh
</style>