import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js';
import Home from '../views/Home.vue'
import Rules from '../views/Rules.vue'
import Gaming from '../views/Gaming.vue'
import History from '../views/History.vue'
import Results from '../views/Results.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/Gaming',
        name: 'Gaming',
        component: Gaming,
    },
    {
        path: '/Rules/:checked',
        name: 'Rules',
        component: Rules
    },
    {
        path: '/Results',
        name: 'Results',
        component: Results
    },
    {
        path: '/History',
        name: 'History',
        component: History
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach(() => {
    store.commit('SET_MASK', false);
    store.commit('SET_LOADING', false);
});

export default router