<template>
<div>
  <div class="card">
    <canvas id="top" @touchstart="eventDown" @touchend="eventUp" @touchmove="eventMove"></canvas>
    <img name="card" @load="initCanvas" style="display:none" src="@/assets/images_n/金卡.png" alt="">
    <img class="card-logo" v-if="!showResults" src="@/assets/images_n/CARD_LOGO.png" alt="">
    <img class="card-hand" v-if="!showResults" src="@/assets/images_n/CARD_手.png" alt="">
    <div class="results winning" v-if="value>0">
      <img class="title" src="@/assets/images_n/恭喜您獲得.png" alt="">
      <img class="line-points" src="@/assets/images_n/LINE POINTS.png" alt="">
      <span class="plate" v-if="showResults">
        <span><em>{{ value }}</em>點</span>
      </span>
    </div>
    <div class="results" v-else>
      <img class="title" src="@/assets/images_n/再接再厲.png" alt="">
      <img class="text" src="@/assets/images_n/分享好友 獲得更多抽獎機會.png" alt="">
    </div>
    </div>
  <div class="count">今天還有<em>{{ quota }}</em>次抽獎次數<br>邀請好友加入，獲得更多機會</div>
</div>
</template>

<script>
export default {
  name: 'Gaming',
  data(){
    return {
      mouseDown: false,
      showResults: false
    }
  },
  computed: {
    quota(){
      return this.$store.state.quota;
    },
    value(){
      return this.$route.query.value;
    },
    url(){
      return this.$route.query.url;
    },
  },
  methods:{
    initCanvas(){
      let canvas = document.querySelector('#top');
      let ctx = canvas.getContext("2d");
      let img = document.images.namedItem('card');
      
      canvas.height = canvas.clientHeight;
      canvas.width = canvas.clientWidth;

      ctx.drawImage(img,0,0,canvas.width,canvas.height);
    },
    eventDown(){
      event.preventDefault();
      this.mouseDown=true;
    },
    eventUp(){
      event.preventDefault();
      this.mouseDown=false;
    },
    eventMove(){
      event.preventDefault();
      if(this.mouseDown){
        let eventData = event;
        if(event.changedTouches){
            eventData = event.changedTouches[event.changedTouches.length-1];
        }
        let ctxTop = document.querySelector('#top').getContext("2d");
        let x = eventData.pageX - document.querySelector('#top').offsetParent.offsetLeft;
        let y = eventData.pageY - document.querySelector('#top').getBoundingClientRect().top;

        ctxTop.globalCompositeOperation = "destination-out";
        ctxTop.beginPath();
        ctxTop.arc(x, y, 50, 0, Math.PI * 2);
        ctxTop.fill();
        this.checkArea()
      }
    },
    checkArea(){
      let ctxTop = document.querySelector('#top').getContext("2d");
      let data = ctxTop.getImageData(0,0,document.querySelector('#top').width,document.querySelector('#top').height).data;
      let n = 0 ;
        for (let i = 0; i < data.length; i++) {
          if (data[i] == 0) {
            n++;
          }
        }
        if (n >= data.length * 0.33) {
          this.mouseDown = false;
          ctxTop.globalCompositeOperation = 'destination-over';
          ctxTop.canvas.style.opacity = 0;
          this.showResults = true;
          this.$router.push({
            name:'Results',
            query:{
              value: this.value,
              url: this.url
            }
          });
        }
    }
  }
}
</script>

<style lang="sass" scoped>
  .card
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    canvas
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    .card-logo
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      height: 31.4%
      z-index: 1
      pointer-events: none
    .card-hand
      position: absolute
      bottom: 5.61%
      left: 50%
      transform: translate(-50%,0%)
      height: 24.83%
      pointer-events: none
    .results
      font-family: 'Helvetica'
      display: flex
      flex-direction: column
      width: 100%
      align-items: center
      img
        max-width: 100%
      .title
        height: 5.05vh
        margin-bottom: 2.36vh
      .text
        height: 7.95vh 
      &.winning
        .title
          height: 5.05vh
          margin-bottom: 2.36vh
        .line-points
          height: 3.15vh
          margin-bottom: 1.77vh
        .plate
          position: relative
          height: 8.6vh
          width: 24.96vh
          background-image: url('../assets/images_n/point-plate.png')
          background-size: 100%
          > span
            position: absolute
            left: 50%
            top:50%
            transform: translate(-50%,-50%)
            color: #ffffff
            font-size: 28px
            text-shadow: 2.298px 1.928px 4.55px rgba(0, 0, 0, 0.75)
            em
              font-style: normal
              font-size: 50px
              font-weight: bold
              margin-right: 5px
              
  .count
    font-size: 18px
    text-align: center
    text-shadow: 2.298px 1.928px 4.5px rgba(0, 0, 0, 0.75)
    color: #fbc34f
    font-family: 'Noto Serif TC'
    em
      padding: 0 .5em
      font-size: 1.3em
      color: #ffffff
      font-style: normal
</style>